import { Storage } from 'app/common/enums/storage';
import { Environment } from 'app/common/interfaces';

import { defaults } from './defaults.environment.sm';
import { localization } from './locale.en-us';

export const environment: Environment = {
    production: true,
    name: 'sm.enquirytracker.net',
    isAllowedInvalidAppSubmission: true,
    domainServer: 'schoolmint-backend-dot-enquirytracker-dev.appspot.com',
    apiUrl: 'https://schoolmint-backend-dot-enquirytracker-dev.appspot.com/',
    googleMapApiKey: 'AIzaSyCK8bRoe_woo-M3IbpWJZaNW132C8v257s',
    googleTranslateApiKey: 'AIzaSyCK8bRoe_woo-M3IbpWJZaNW132C8v257s',
    storageType: Storage.LocalStorage,
    reCaptchaSiteKey: '6LdBp60UAAAAAFybBZaEiBshGVKRexxEWObKcWOU',
    reCaptchaV2SiteKey: '6LegAkQUAAAAAOoGejQnzUhyjUInJkra0yGmR8SW',
    ...defaults,
    localization,
};
